export type BlogLocalizedPages = "news" | "noticias" | "blog"
export type BuyHouseLocalizedPages =
  | "houses-for-sale"
  | "casas-en-venta"
  | "case-in-vendita"
  | "comprar-casa"
  | "acheter-bien"
export type FaqLocalizedPages = "faq"
export type FaqsLocalizedPages = "faqs"
export type PressLocalizedPage = "press" | "press" | "dicono-di-noi" | "presse"
export type ContactUsLocalizedPage = "contact" | "contacto" | "contattaci"
export type PerfectMatchLocalizedPage = "trova-un-acquirente-perfetto" | "encuentra-al-comprador-perfecto"
export type TermsLocalizedPage =
  | "terms-and-conditions"
  | "termini-e-condizioni"
  | "terminos-y-condiciones"
  | "termos-e-condicoes"
  | "conditions-generales"
export type LegalEntitiesLocalizedPage = "legal-entities" | "dati-societari" | "datos-de-la-sociedad"
export type LegalMentionsLocalizedPage = "legal-mentions" | "mentions-legales"
export type SellHouseLocalizedPages = "sell-house" | "vender-casa" | "vendi-casa" | "vendre-bien"
export type AboutUsLocalizedPages = "about-us" | "a-propos"
export type MortgageLocalizedPages = "mutui" | "mortgage" | "hipotecas"
export type SignatureOtpLocalizedPage = "digital-signature-otp" | "firma-elettronica-otp"
export type SignaturePrivacyLocalizedPage = "digital-signature-privacy" | "firma-elettronica-privacy"
export type SignatureLocalizedPage = "digital-signature" | "firma-elettronica" | "firma-electronica"
export type GuidesLocalizedPages = "guides" | "guide" | "guia"
export type MatchLocalizedPages = "match" | "come-mettere-in-vendita-casa-trovare-acquirente-offerta" | ""
export type ComplianceLocalizedPage = "compliance"
export type PriorityLocalizedPages = "go-priority"

export type VirtualTools = "virtual-tools"
export type Agent = "agent" | "diventa-agente" | "devenir-agent"
export type Engineering = "engineering"

export enum PageKind {
  aboutUs = "about-us",
  agent = "agent",
  blog = "blog",
  buyHouse = "buy-house",
  compliance = "compliance",
  contactUs = "contact-us",
  digitalSignature = "digital-signature",
  digitalSignatureOtp = "digital-signature-otp",
  digitalSignaturePrivacy = "digital-signature-privacy",
  engineering = "engineering",
  faq = "faq",
  faqs = "faqs",
  guides = "guides",
  legalEntities = "legal-entities",
  legalMentions = "legal-mentions",
  match = "match",
  mortgage = "mortgage",
  perfectMatch = "perfect-match",
  press = "press",
  sellHouse = "sell-house",
  termsAndConditions = "terms-and-conditions",
  virtualTools = "virtual-tools",
  priority = "priority",
}

export type LocalizedPages =
  | BlogLocalizedPages
  | BuyHouseLocalizedPages
  | FaqLocalizedPages
  | FaqsLocalizedPages
  | PressLocalizedPage
  | ComplianceLocalizedPage
  | ContactUsLocalizedPage
  | TermsLocalizedPage
  | LegalEntitiesLocalizedPage
  | LegalMentionsLocalizedPage
  | SellHouseLocalizedPages
  | AboutUsLocalizedPages
  | MortgageLocalizedPages
  | SignatureLocalizedPage
  | SignatureOtpLocalizedPage
  | SignaturePrivacyLocalizedPage
  | PerfectMatchLocalizedPage
  | GuidesLocalizedPages
  | MatchLocalizedPages
  | PriorityLocalizedPages
  | VirtualTools
  | Agent
  | Engineering

export type Pages = {
  [lang in Locale]: {
    [PageKind.blog]: BlogLocalizedPages
    [PageKind.buyHouse]: BuyHouseLocalizedPages
    [PageKind.faq]: FaqLocalizedPages
    [PageKind.faqs]: FaqsLocalizedPages
    [PageKind.compliance]: ComplianceLocalizedPage
    [PageKind.contactUs]: ContactUsLocalizedPage
    [PageKind.press]: PressLocalizedPage
    [PageKind.sellHouse]: SellHouseLocalizedPages
    [PageKind.termsAndConditions]: TermsLocalizedPage
    [PageKind.legalEntities]: LegalEntitiesLocalizedPage
    [PageKind.legalMentions]: LegalMentionsLocalizedPage
    [PageKind.aboutUs]: AboutUsLocalizedPages
    [PageKind.mortgage]: MortgageLocalizedPages
    [PageKind.digitalSignature]: SignatureLocalizedPage
    [PageKind.digitalSignatureOtp]: SignatureOtpLocalizedPage
    [PageKind.digitalSignaturePrivacy]: SignaturePrivacyLocalizedPage
    [PageKind.perfectMatch]: PerfectMatchLocalizedPage
    [PageKind.guides]: GuidesLocalizedPages
    [PageKind.match]: MatchLocalizedPages
    [PageKind.priority]: PriorityLocalizedPages
    [PageKind.virtualTools]: VirtualTools
    [PageKind.agent]: Agent
    [PageKind.engineering]: Engineering
  }
}

export type LanguageAlternate = {
  href: string
  hrefLang: string
}
