import React, { createElement } from "react"

import theme from "../design-lib/style-theme/theme.css"

import { typography } from "./TypographyOld.css"

export type Style =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "bodyL"
  | "bodyL_strong"
  | "bodyM"
  | "bodyM_strong"
  | "bodyS"
  | "bodyS_strong"
  | "heroTitle"
type Alignment = "left" | "center" | "right" | "inherit"
type HtmlElement = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span" | "div"

interface Props {
  alignment?: Alignment
  children?: React.ReactNode
  className?: string
  color?: string
  component?: HtmlElement
  variant?: Style
  withMargin?: boolean
}

/**
 * This component is a great example on how to artificially complicate a trivial task.
 * as of the end of 2023 I'm just refactoring this to remove Emotion and use Vanilla Extract,
 * but I'm not changing anything elese.
 *
 * We should deprecate and remove this as soon as possible
 */
export const TypographyOld: React.FC<Props> = ({
  alignment = "inherit",
  children,
  className,
  color = theme.colors.greyscale600,
  component = "p",
  variant = "bodyL",
  withMargin = false,
  ...rest
}) => {
  const classes = [className, typography({ variant })].join(" ")

  return createElement(
    component,
    {
      className: classes,
      /**
       * we should have used the third parameter of createElement, but we can't since
       * we are also injecting other raw tags within the component...
       */
      dangerouslySetInnerHTML: {
        __html: children,
      },
      style: {
        color: color,
        marginBottom: withMargin ? "1em" : 0,
        textAlign: alignment,
      },
      ...rest,
    }
    // , children
  )
}
